import styled from "styled-components"

import { Tooltip } from "@material-ui/core"

import SearchFilter from "src/components/Filter/SearchFilter"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  DescriptionWrapper,
  Ellipsis,
  FilterWrapper,
  IdWrapper,
  ParadiseLayout,
  PillsWrapper,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { useImpersonate } from "src/components/Paradise/useImpersonate"
import {
  TableColumn,
  useTableColumns,
} from "src/components/Paradise/useTableColumns/useTableColumns"
import { chargebeeUrl, intercomUrl, kibanaUrl } from "src/constants/hrefs"
import { useFetchParadiseUsers } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { IParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { isSuperAdmin } from "src/data/user/logic/accessLogic"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { useSearchParams } from "src/router/useSearchParams"
import { IMButtonLegacyProps, MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { colors } from "src/ui/colors"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { Hoverable } from "src/ui/Hoverable/Hoverable"
import InfoIcon from "src/ui/icons/info.svg"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const tableColumns: TableColumn<IParadiseUser>[] = [
  {
    label: "User id",
    value: "user_id",
    disabled: true,
    columnWidth: "min-content",
    disableClickPropagation: true,
    render: (data) => (
      <div>
        <IdWrapper>
          <Hoverable
            trigger={
              <InternalLink to={Routes.ParadiseUser.location(data.id).pathname}>
                {data.id}
              </InternalLink>
            }
            content={
              <HoverableWrapper>
                <MButtonLegacy
                  variant="contained"
                  color="default"
                  onClick={() => window.open(intercomUrl(data.email))}
                >
                  Intercom
                </MButtonLegacy>
                <MButtonLegacy
                  variant="contained"
                  color="default"
                  onClick={() => window.open(kibanaUrl(data.user_id))}
                >
                  Kibana
                </MButtonLegacy>
                <MButtonLegacy
                  variant="contained"
                  color="default"
                  onClick={() => window.open(chargebeeUrl(data.user_id))}
                >
                  Chargebee
                </MButtonLegacy>
                <ImpersonateButton
                  userId={data.id}
                  variant="contained"
                  color="default"
                />
              </HoverableWrapper>
            }
          />
          {data.deleted && (
            <Tooltip title="Deleted">
              <InfoIcon width={12} fill={colors.systemEmergencyForeground} />
            </Tooltip>
          )}
        </IdWrapper>
      </div>
    ),
  },
  {
    label: "Email",
    value: "email",
    columnWidth: 300,
    render: (data) => <Ellipsis>{data.email}</Ellipsis>,
  },
  {
    label: "Name",
    value: "name",
    disabled: true,
    columnWidth: 300,
    render: (data) => <Ellipsis>{data.full_name}</Ellipsis>,
  },
  {
    label: "Roles",
    value: "roles",
    columnWidth: "auto",
    render: (data) => (
      <div>
        <PillsWrapper $noWrap>
          {data.roles.map((role, i) => (
            <MBadge key={i} size="small" color="neutral">
              {role}
            </MBadge>
          ))}
        </PillsWrapper>
      </div>
    ),
  },
]

const LIMIT = 50

export function ParadiseUsers() {
  const { navigate } = useRouter()
  const user = useGetUser()

  const { limit, offset, setOffset } = useUrlPager({ initialLimit: LIMIT })

  const { searchParams: filter, setSearchParams: setFilter } = useSearchParams({
    keys: [
      {
        key: "user_id",
        type: "string",
      },
      {
        key: "name",
        type: "string",
      },
      {
        key: "email",
        type: "string",
      },
    ],
  })

  const fetchedUsers = useFetchParadiseUsers({
    filter: {
      limit,
      offset,
      user_id: filter.user_id || undefined,
      name: filter.name || undefined,
      email: filter.email || undefined,
      sort: "name",
      sort_by: "asc",
    },
    options: {
      keepPreviousData: true,
    },
  })

  const {
    headerElements,
    interactiveColumns,
    interactiveVisibleColumns,
    rows,
    setColumnVisibility,
    templateColumns,
  } = useTableColumns({
    columns: tableColumns,
    data: fetchedUsers.data?.users,
    options: { localStorageKey: "minut.paradise.users.table" },
  })

  function handleFilterChange(filterKey: keyof typeof filter, value: string) {
    setOffset(0)
    setFilter(filterKey, value)
  }

  return (
    <ParadiseLayout>
      <Titlebar
        title="Users"
        description={
          <DescriptionWrapper>
            <MText variant="body">Total:</MText>
            <MText variant="body">
              {!fetchedUsers.isLoading ? (
                fetchedUsers.data?.total_count
              ) : (
                <MSkeleton width="8ch" />
              )}
            </MText>
          </DescriptionWrapper>
        }
      />
      <TopWrapper>
        <FilterWrapper>
          <SearchFilter
            initialValue={filter.user_id ?? undefined}
            onChange={(v) => handleFilterChange("user_id", v)}
            label="Search by id"
            fullWidth
          />
          <SearchFilter
            initialValue={filter.email ?? undefined}
            onChange={(v) => handleFilterChange("email", v)}
            label="Search by email"
            fullWidth
          />
          <SearchFilter
            initialValue={filter.name ?? undefined}
            onChange={(v) => handleFilterChange("name", v)}
            label="Search by name"
            fullWidth
          />
        </FilterWrapper>
        <DropdownMultiSelect
          label="Columns"
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          options={interactiveColumns}
          onChange={({ checked, option }) => {
            setColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>
      <ParadiseTable
        rows={rows ?? []}
        templateColumns={templateColumns}
        header={headerElements}
        onRowClick={(index) => {
          if (!isSuperAdmin(user.roles)) return

          if (fetchedUsers.data?.users) {
            navigate(
              Routes.ParadiseUser.location(
                fetchedUsers.data?.users[index].user_id
              )
            )
          }
        }}
        error={{
          hasError: fetchedUsers.isError,
          title: fetchedUsers.error?.message,
        }}
      />
      <ParadisePager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchedUsers.data?.total_count}
      />
    </ParadiseLayout>
  )
}

const HoverableWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
  padding: ${spacing.M};
  width: 200px;
`
function ImpersonateButton({
  userId,
  ...props
}: { userId: string } & IMButtonLegacyProps) {
  const impersonate = useImpersonate()

  return (
    <MButtonLegacy onClick={() => impersonate(userId)} {...props}>
      Impersonate
    </MButtonLegacy>
  )
}
